/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ListFormComponentComponent } from '@shared/components/list-form-component/list-form-component.component';
import { ColoredSelectButton } from '@shared/models/colored-select-button';

@Component({
  selector: 'app-colored-select-button',
  templateUrl: './colored-select-button.component.html',
  styleUrls: ['./colored-select-button.component.scss'],
})
export class ColoredSelectButtonComponent extends ListFormComponentComponent {
  @ViewChildren('checkbox') checkboxes: QueryList<any>;
  item: ColoredSelectButton;
  radioVal: any;

  updateValue(value: any): void {
    this.radioVal = value;
    super.updateValue(value);
  }

  clickRadio(event: Event, value: any) {
    if (!this.item.multiselect && !this.item.formControl.disabled) {
      event.preventDefault();
      if (this.radioVal !== value) {
        this.radioVal = value;
        this.item.formControl.patchValue(value);
      } else {
        this.radioVal = undefined;
        this.item.formControl.reset();
      }
    }

    if (!this.item.formControl.disabled) {
      this.emitEvent();
    }
  }

  keyDownRadio(event: KeyboardEvent, value: any, inputId: string) {
    // if the key is space or enter simulate a click
    if (!['Space', 'Enter'].includes(event.code)) return;

    if (this.item.multiselect && !this.item.formControl.disabled) {
      // simulate a click on the hidden p-checkbox
      this.checkboxes
        .find((el) => el.inputId === inputId)
        .inputViewChild.nativeElement.click();
    } else {
      this.clickRadio(event, value);
    }
  }
}
