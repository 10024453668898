/* Copyright 2023 (Unpublished) Verto Inc. */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export type SupportedLanguage = 'en' | 'fr';
type Locales = {
  [lang in SupportedLanguage]: { [key: string]: string };
};

@Injectable()
export class LanguageService {
  private _currentLanguage;
  private _locales: Locales = {
    en: {},
    fr: {},
  };

  supportedLanguages: SupportedLanguage[] = [];

  constructor() {
    this._currentLanguage = new BehaviorSubject<SupportedLanguage>(
      (localStorage.getItem('verto_lang') as SupportedLanguage) || 'en'
    );

    this._currentLanguage.subscribe((lang) => {
      localStorage.setItem('verto_lang', lang);
    });
  }

  $(key: string): string {
    const locale = this._locales[this.getLanguage()];
    return locale[key];
  }

  setLocale(lang: SupportedLanguage, localeObj: { [key: string]: string }): void {
    this._locales[lang] = localeObj;
    this.supportedLanguages.push(lang);
    this.supportedLanguages = [...new Set(this.supportedLanguages)];
  }

  getLanguage(): SupportedLanguage {
    return this._currentLanguage.value;
  }

  getLanguage$(): Observable<SupportedLanguage> {
    return this._currentLanguage.asObservable();
  }

  setLanguage(lang: SupportedLanguage): void {
    this._currentLanguage.next(lang);
  }
}
