/* Copyright 2023 (Unpublished) Verto Inc. */

import { Component, inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { ShellLoader } from '../ShellLoader';

@Component({
  template: ``,
  standalone: true,
})
export class CodeFlowComponent implements OnInit {
  private _oauthService = inject(OAuthService);
  private _route = inject(ActivatedRoute);
  private _shellLoader = inject(ShellLoader);

  ngOnInit() {
    this._route.queryParams.subscribe((params) => {
      const { launch } = params;
      const { iss } = params;
      const aud = iss;

      const missingToken = !this._oauthService.hasValidAccessToken();
      const autoLaunch =
        this._shellLoader.config.application.smartOnFhirConfig.autoLaunchOnUnauthorized;

      if (launch || (missingToken && autoLaunch)) {
        const discoveryUrl =
          this._shellLoader.config.application.smartOnFhirConfig.discoveryDocumentUrl;

        this._oauthService.loadDiscoveryDocument(discoveryUrl).then(() => {
          this._oauthService.initCodeFlow('', { launch, iss, aud }); // redirect to oauth provider with authorization code request
        });
      }
    });
  }
}
